import React, { useState } from "react"
import Layout from "../components/layout"

export default function FormPage() {
  const [formState, setFormState] = useState("idle")

  const handleSubmit = e => {
    e.preventDefault()

    const form = e.currentTarget
    const formData = new FormData(e.target)

    // To further customize the data sent, see: https://javascript.info/formdata
    // For example:
    formData.set("formId", form.getAttribute("data-id"))
    formData.set("origin", window.location.host)

    setFormState("sending")
    fetch("/.netlify/functions/post-form-data", {
      method: "POST",
      body: formData,
    })
      .then(res => res.json())
      .then(result => {
        setFormState("success")
        console.log("result", result)
      })
      .catch(err => {
        setFormState("failed")
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          setFormState("idle")
        }, 10000)
      })
  }

  return (
    <Layout>
      {formState === "sending" && <p>Sending form submission...</p>}
      {formState === "success" && <p>Form submission sent!</p>}
      {formState === "failed" && (
        <p>Something went wrong sending form data...</p>
      )}
      <form
        data-id="60f64031-db19-442a-b911-c51cf6ef3a46"
        onSubmit={handleSubmit}
      >
        Name: <input type="text" name="Name" />
        <button type="submit">Submit</button>
      </form>
    </Layout>
  )
}
